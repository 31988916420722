export interface ICampusData {
  name: string
  code: string
  link: string
  slug: string
}

export function getCampuses(): Array<ICampusData> {
  return [
    {
      name: `Barrie`,
      code: `BA`,
      link: `/about-georgian/campuses/barrie-campus/`,
      slug: `barrie-campus`,
    },
    {
      name: `Barrie Downtown`,
      code: `DT`,
      link: `/about-georgian/campuses/barrie-campus/downtown-barrie-location/`,
      slug: `downtown-barrie-location`,
    },
    {
      name: `Midland`,
      code: `MI`,
      link: `/about-georgian/campuses/midland-campus/`,
      slug: `midland-campus`,
    },
    {
      name: `Muskoka`,
      code: `MU`,
      link: `/about-georgian/campuses/muskoka-campus/`,
      slug: `muskoka-campus`,
    },
    {
      name: `Online`,
      code: `OL`,
      link: `/academics/program-delivery/#online`,
      slug: `online-campus`,
    },
    {
      name: `Orangeville`,
      code: `OV`,
      link: `/about-georgian/campuses/orangeville-campus/`,
      slug: `orangeville-campus`,
    },
    {
      name: `Orillia`,
      code: `OR`,
      link: `/about-georgian/campuses/orillia-campus/`,
      slug: `orillia-campus`,
    },
    {
      name: `Owen Sound`,
      code: `OS`,
      link: `/about-georgian/campuses/owen-sound-campus/`,
      slug: `owen-sound-campus`,
    },
    {
      name: `South Georgian Bay`,
      code: `SG`,
      link: `/about-georgian/campuses/south-georgian-bay-campus/`,
      slug: `south-georgian-bay-campus`,
    },
  ]
}

export function campusNameFromCode(campusCode: string): string | undefined {
  return getCampuses().find(c => c.code === campusCode)?.name
}

export function campusUrlFromName(campusName: string): string | undefined {
  return getCampuses().find(
    c => c.name.toLowerCase() === campusName.toLowerCase()
  )?.link
}

export function caseCampusName(campusName: string): string {
  return campusName
    .toLowerCase()
    .split(` `)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(` `)
}

export function buildingCodeToBuildingLetter(buildingCode: string): string {
  return buildingCode.substr(3, 1)
}
