exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academics-all-courses-data-tsx": () => import("./../../../src/pages/academics/all-courses-data.tsx" /* webpackChunkName: "component---src-pages-academics-all-courses-data-tsx" */),
  "component---src-pages-academics-all-programs-data-tsx": () => import("./../../../src/pages/academics/all-programs-data.tsx" /* webpackChunkName: "component---src-pages-academics-all-programs-data-tsx" */),
  "component---src-pages-academics-courses-[slug]-tsx": () => import("./../../../src/pages/academics/courses/[slug].tsx" /* webpackChunkName: "component---src-pages-academics-courses-[slug]-tsx" */),
  "component---src-pages-academics-programs-list-tsx": () => import("./../../../src/pages/academics/programs-list.tsx" /* webpackChunkName: "component---src-pages-academics-programs-list-tsx" */),
  "component---src-pages-blog-test-tsx": () => import("./../../../src/pages/blog-test.tsx" /* webpackChunkName: "component---src-pages-blog-test-tsx" */),
  "component---src-pages-gc-course-uri-tsx": () => import("./../../../src/pages/{GcCourse.uri}.tsx" /* webpackChunkName: "component---src-pages-gc-course-uri-tsx" */),
  "component---src-pages-news-events-all-events-test-tsx": () => import("./../../../src/pages/news-events/all-events-test.tsx" /* webpackChunkName: "component---src-pages-news-events-all-events-test-tsx" */),
  "component---src-pages-post-preview-tsx": () => import("./../../../src/pages/post-preview.tsx" /* webpackChunkName: "component---src-pages-post-preview-tsx" */),
  "component---src-pages-react-tests-tsx": () => import("./../../../src/pages/react-tests.tsx" /* webpackChunkName: "component---src-pages-react-tests-tsx" */),
  "component---src-pages-testpage-tsx": () => import("./../../../src/pages/testpage.tsx" /* webpackChunkName: "component---src-pages-testpage-tsx" */),
  "component---src-pages-wp-event-uri-tsx": () => import("./../../../src/pages/{WpEvent.uri}.tsx" /* webpackChunkName: "component---src-pages-wp-event-uri-tsx" */),
  "component---src-pages-wp-page-uri-tsx": () => import("./../../../src/pages/{wpPage.uri}.tsx" /* webpackChunkName: "component---src-pages-wp-page-uri-tsx" */),
  "component---src-pages-wp-post-uri-tsx": () => import("./../../../src/pages/{wpPost.uri}.tsx" /* webpackChunkName: "component---src-pages-wp-post-uri-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "slice---src-slices-featured-blogs-tsx": () => import("./../../../src/slices/featured-blogs.tsx" /* webpackChunkName: "slice---src-slices-featured-blogs-tsx" */),
  "slice---src-slices-important-dates-tsx": () => import("./../../../src/slices/important-dates.tsx" /* webpackChunkName: "slice---src-slices-important-dates-tsx" */),
  "slice---src-slices-latest-news-tsx": () => import("./../../../src/slices/latest-news.tsx" /* webpackChunkName: "slice---src-slices-latest-news-tsx" */),
  "slice---src-slices-upcoming-events-tsx": () => import("./../../../src/slices/upcoming-events.tsx" /* webpackChunkName: "slice---src-slices-upcoming-events-tsx" */)
}

