import React, { useState, useEffect, useRef } from "react"
// import { GlobalHotKeys } from "react-hotkeys"
import IWpPage from "../interfaces/IWpPage"
import IWpPost from "../interfaces/IWpPost"
import { caseCampusName, ICampusData } from "../utils/georgian"
import isBrowser from "../utils/isBrowser"

interface IUserInterfaceContextProviderProps {
  children: React.ReactNode
}

interface IImportantAlert {
  post_content: string
  type: string
}

export interface IUserInterfaceContext {
  importantAlertsJson: Array<IImportantAlert> | null
  mobileMenuOpen: boolean
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleMobileMenuOpen: () => void
  localMobileMenuOpen: boolean
  setLocalMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleLocalMobileMenuOpen: () => void
  currentPost:
    | Queries.PageContentQuery["wpPage"]
    | Queries.BlogCategoryPageQuery["category"]
    | IWpPost
    | null
  setCurrentPost: React.Dispatch<
    React.SetStateAction<
      | Queries.PageContentQuery["wpPage"]
      | Queries.BlogCategoryPageQuery["category"]
      | IWpPost
      | null
    >
  >
  searchOpen: boolean
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  megaMenuActive: string
  setMegaMenuActive: React.Dispatch<React.SetStateAction<string>>
  showMainContent: boolean
  setShowMainContent: React.Dispatch<React.SetStateAction<boolean>>
  setShowMainContentWithSideEffects: (show: boolean) => void
  showAwkward: boolean
  setShowAwkward: React.Dispatch<React.SetStateAction<boolean>>
  tocPortalRef: React.RefObject<HTMLDivElement>
  tocIsInViewport: boolean
  tocMobilePortalRef: React.RefObject<HTMLDivElement>
  setTocIsInViewport: React.Dispatch<React.SetStateAction<boolean>>
  headerIsInViewport: boolean
  setHeaderIsInViewport: React.Dispatch<React.SetStateAction<boolean>>
  mobileTocOpen: boolean
  setMobileTocOpen: React.Dispatch<React.SetStateAction<boolean>>
  headingInView: string
  setHeadingInView: React.Dispatch<React.SetStateAction<string>>
  ptsCourseFilters: {
    area?: Array<string>
    time?: Array<string>
    campus?: Array<string>
    keyword?: Array<string>
  }
  ptsProgramFilters: {
    area?: Array<string>
    time?: Array<string>
    credential?: Array<string>
    campus?: Array<string>
    keyword?: Array<string>
  }
  setPtsCourseFilter: (filter: string, value: Array<string> | undefined) => void
  setPtsProgramFilter: (
    filter: string,
    value: Array<string> | undefined
  ) => void
  ftProgramFilters: {
    keyword: Array<string>
    areas: Array<string>
    campus: Array<string>
    start: Array<string>
  }
  setFtProgramFilter: (filter: string, value: Array<string> | undefined) => void
  gcProgramFilters: {
    keyword: Array<string>
    areas: Array<string>
    campus: Array<string>
    start: Array<string>
    credential: Array<string>
    delivery: Array<string>
  }
  setGcProgramFilter: (filter: string, value: Array<string> | undefined) => void
  activePageTab: string
  setActivePageTab: React.Dispatch<React.SetStateAction<string>>
  routeChangedTime: number
}

export const UserInterfaceContext = React.createContext<IUserInterfaceContext>({
  importantAlertsJson: null,
  mobileMenuOpen: false,
  setMobileMenuOpen: () => null,
  toggleMobileMenuOpen: () => null,
  localMobileMenuOpen: false,
  setLocalMobileMenuOpen: () => null,
  toggleLocalMobileMenuOpen: () => null,
  currentPost: null,
  setCurrentPost: () => null,
  searchOpen: false,
  setSearchOpen: () => null,
  searchTerm: ``,
  setSearchTerm: () => null,
  megaMenuActive: ``,
  setMegaMenuActive: () => null,
  showMainContent: true,
  setShowMainContent: () => null,
  setShowMainContentWithSideEffects: () => null,
  showAwkward: true,
  setShowAwkward: () => null,
  tocPortalRef: React.createRef<HTMLDivElement>(),
  tocIsInViewport: true,
  tocMobilePortalRef: React.createRef<HTMLDivElement>(),
  setTocIsInViewport: () => null,
  headerIsInViewport: true,
  setHeaderIsInViewport: () => null,
  mobileTocOpen: true,
  setMobileTocOpen: () => null,
  headingInView: ``,
  setHeadingInView: () => null,
  ptsCourseFilters: {},
  ptsProgramFilters: {},
  setPtsCourseFilter: () => null,
  setPtsProgramFilter: () => null,
  ftProgramFilters: {
    keyword: [],
    areas: [],
    campus: [],
    start: [],
  },
  setFtProgramFilter: () => null,
  gcProgramFilters: {
    keyword: [],
    areas: [],
    campus: [],
    start: [],
    credential: [],
    delivery: [],
  },
  setGcProgramFilter: () => null,
  activePageTab: ``,
  setActivePageTab: () => null,
  routeChangedTime: 0,
})

export default function UserInterfaceContextProvider({
  children,
}: IUserInterfaceContextProviderProps): JSX.Element {
  const [importantAlertsJson, setImportantAlertsJson] =
    useState<Array<IImportantAlert> | null>(null)
  const [routeChangedTime, setRouteChangedTime] = useState<number>(0)
  const tocPortalRef = React.useRef<HTMLDivElement>(null)
  const tocMobilePortalRef = React.useRef<HTMLDivElement>(null)
  const [megaMenuActive, setMegaMenuActive] = useState(``)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [localMobileMenuOpen, setLocalMobileMenuOpen] = useState(false)
  const [currentPost, setStateCurrentPost] = useState<IWpPage | null>(null)
  const toggleMobileMenuOpen = (): void => setMobileMenuOpen(!mobileMenuOpen)
  const toggleLocalMobileMenuOpen = (): void =>
    setLocalMobileMenuOpen(!localMobileMenuOpen)
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState(``)
  const [showMainContent, setShowMainContent] = useState(true)
  const [showAwkward, setShowAwkward] = useState(false)
  const [tocIsInViewport, setTocIsInViewport] = useState(true)
  const [headerIsInViewport, setHeaderIsInViewport] = useState(true)
  const [mobileTocOpen, setMobileTocOpen] = useState(false)
  const [headingInView, setStateHeadingInView] = useState(``)
  const [ptsCourseFilters, setPtsCourseFilters] = useState({})
  const [ptsProgramFilters, setPtsProgramFilters] = useState({})
  const [ftProgramFilters, setFtProgramFilters] = useState({})
  const [gcProgramFilters, setGcProgramFilters] = useState({})
  const [activePageTab, setActivePageTab] = useState(``)

  const setCurrentPost = (post: IWpPage | null): void => {
    setStateCurrentPost(post)
  }

  const setFtProgramFilter = (
    filter: string,
    value: Array<string> | undefined
  ): void => {
    setFtProgramFilters(prevState => {
      return {
        ...prevState,
        [filter]: value,
      }
    })
  }
  const setGcProgramFilter = (
    filter: string,
    value: Array<string> | undefined
  ): void => {
    setGcProgramFilters(prevState => {
      return {
        ...prevState,
        [filter]: value,
      }
    })
  }

  const setPtsCourseFilter = (
    filter: string,
    value: Array<string> | undefined
  ): void => {
    setPtsCourseFilters({
      ...ptsCourseFilters,
      [filter]: value,
    })
  }
  const setPtsProgramFilter = (
    filter: string,
    value: Array<string> | undefined
  ): void => {
    setPtsProgramFilters({
      ...ptsProgramFilters,
      [filter]: value,
    })
  }

  // process query params into userinterface context values
  const prevAlertFetchRef = useRef(routeChangedTime)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const campus = params.get(`campus`)
    const area = params.get(`area`)
    const time = params.get(`study`)
    const keyword = params.get(`keyword`)
    const start = params.get(`start`)
    const credential = params.get(`credential`)
    const delivery = params.get(`delivery`)
    if (start) {
      setFtProgramFilter(`start`, start.split(`,`))
      setGcProgramFilter(`start`, start.split(`,`))
    }
    if (campus) {
      const casedCampusArray = caseCampusName(campus)?.split(`,`)
      setFtProgramFilter(`campus`, casedCampusArray)
      setGcProgramFilter(`campus`, casedCampusArray)
      setPtsCourseFilter(`campus`, casedCampusArray)
      setPtsProgramFilter(`campus`, casedCampusArray)
    }
    if (area) {
      const areaArray = area?.split(`,`)
      setFtProgramFilter(`areas`, areaArray)
      setGcProgramFilter(`areas`, areaArray)
      setPtsCourseFilter(`area`, areaArray)
      setPtsProgramFilter(`area`, areaArray)
    }
    if (keyword) {
      const keywordArray = keyword?.split(`,`)
      setFtProgramFilter(`keyword`, keywordArray)
      setGcProgramFilter(`keyword`, keywordArray)
      setPtsCourseFilter(`keyword`, keywordArray)
      setPtsProgramFilter(`keyword`, keywordArray)
    }
    if (time) {
      const timeArray = time?.split(`,`)
      setFtProgramFilter(`time`, timeArray)
      setGcProgramFilter(`time`, timeArray)
      setPtsCourseFilter(`time`, timeArray)
      setPtsProgramFilter(`time`, timeArray)
    }
    if (credential) {
      const credentialArray = credential?.split(`|`)
      setGcProgramFilter(`credential`, credentialArray)
    }
    if (delivery) {
      const deliveryArray = delivery?.split(`,`)
      setGcProgramFilter(`delivery`, deliveryArray)
    }
    async function getImportantAlerts(): Promise<void> {
      const response = await fetch(`/api/get-important-alerts`)
      // console.log({ response })
      const json = await response.json()
      setImportantAlertsJson(() => json)
    }
    // refetch alerts if route changes and it's been more than 2 minutes since last fetch
    if (routeChangedTime - prevAlertFetchRef.current > 120000) {
      // console.log(`fetching alerts after 2 minutes`)
      getImportantAlerts()
      prevAlertFetchRef.current = routeChangedTime
    }
  }, [routeChangedTime])

  const setHeadingInView = (heading: string): void => {
    setStateHeadingInView(heading)
    history.replaceState({}, ``, heading)
  }

  const setShowMainContentWithSideEffects = (show: boolean): void => {
    setShowMainContent(show)
    if (show === false) {
      if (localMobileMenuOpen) setLocalMobileMenuOpen(false)
    }
  }

  // remove hotkeys for now
  // const keyMap = {
  //   SEARCH: [`command+k`, `control+k`],
  //   EDIT: [`command+e`, `control+e`, `ctrl+e`],
  // }
  // const handlers = {
  //   SEARCH: (): void => {
  //     setSearchOpen(!searchOpen)
  //     if (searchOpen) setSearchTerm(``)
  //   },
  //   EDIT: (): void => {
  //     if (currentPost?.databaseId) {
  //       window.open(
  //         `https://georgiangatsby.wpengine.com/wp-admin/post.php?post=${currentPost.databaseId}&action=edit`
  //       )
  //     } else {
  //       alert(`Sorry, unknown post.`)
  //     }
  //   },
  // }

  if (isBrowser)
    window.georgian_onRouteUpdateDelayed = () => setShowAwkward(true)
  if (isBrowser)
    window.georgian_onRouteUpdate = () => {
      setShowAwkward(false)
      setShowMainContent(true)
      setRouteChangedTime(Date.now())
    }

  return (
    <UserInterfaceContext.Provider
      value={{
        importantAlertsJson,
        mobileMenuOpen,
        setMobileMenuOpen,
        toggleMobileMenuOpen,
        localMobileMenuOpen,
        setLocalMobileMenuOpen,
        toggleLocalMobileMenuOpen,
        currentPost,
        setCurrentPost,
        searchOpen,
        setSearchOpen,
        searchTerm,
        setSearchTerm,
        megaMenuActive,
        setMegaMenuActive,
        showMainContent,
        setShowMainContent,
        setShowMainContentWithSideEffects,
        showAwkward,
        setShowAwkward,
        tocPortalRef,
        tocMobilePortalRef,
        tocIsInViewport,
        setTocIsInViewport,
        headerIsInViewport,
        setHeaderIsInViewport,
        mobileTocOpen,
        setMobileTocOpen,
        headingInView,
        setHeadingInView,
        ptsCourseFilters,
        ptsProgramFilters,
        setPtsCourseFilter,
        setPtsProgramFilter,
        ftProgramFilters,
        setFtProgramFilter,
        gcProgramFilters,
        setGcProgramFilter,
        activePageTab,
        setActivePageTab,
        routeChangedTime,
      }}
    >
      {/* <GlobalHotKeys keyMap={keyMap} handlers={handlers} /> */}
      {children}
    </UserInterfaceContext.Provider>
  )
}
